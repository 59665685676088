import React, { useState } from "react"
import ReactDOMServer from "react-dom/server";
import mustache from "mustache/mustache"
import t from "../../services/GetTranslationValue"
import r from "../../services/Router"
import CasinoHelper from "../../services/casino-bonus-helpers"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"
import { Link } from "gatsby"
import styles from "./item.module.scss"

import Nullif from "../../services/NullIf"

import h from "../../services/GenericHelpers"
import { getMaxBetText } from "../../services/casino-property-renders"
import { getLocaleAlias } from "../../graphql-assistant/createLocaleTextGetter"

const addNamedClass = (casino, suffix) => {
  if (casino.name === 'Rizk')
    return casino.name + '-' + suffix;

  return '';
}

const getWageringText = (casino, translations) => {
  let bonus = CasinoHelper.getBonusToUse(casino, false)
  if (bonus && bonus.wageringRequirement && bonus.wageringRequirementType)
    return `${bonus.wageringRequirement}${t.get(translations, bonus.wageringRequirementType)}`

  if (!bonus)
    return '-'

  return "0x"
}

const makeFreespinCards = (casino, translations, locale) => {

  let fsND = CasinoHelper.GetFreespinNoDepositBonus(casino);
  let fs = CasinoHelper.GetFirstDepositBonus(casino);

  return <div>
    {
      [makeFreespinCard(fs, true, translations, locale, casino),
      makeFreespinCard(fsND, false, translations, locale, casino)]
    }
  </div>
}

const makeFreespinCard = (bonus, requiresDeposit, translations, locale, casino) => {
  return (bonus && !CasinoHelper.IsFirstDepositBonus(bonus.type._id)) ? bonus && bonus.freespinsAmount > 0 &&
    <div className={`free-spin ${styles.freeSpin}`}>
      <p className={`free-spin-text ${styles.freeSpinText}`}>{t.get(translations, 'freespin')}</p>
      <p className={`free-spin-amount ${styles.freeSpinAmount}`}>{bonus.freespinsAmount}</p>
      <p className={`free-spin-deposit ${styles.freeSpinDeposit}`}>{requiresDeposit ? t.get(translations, 'with') : t.get(translations, 'without')} {t.get(translations, 'deposit')}</p>
      <p className={`${styles.bonusCardsWagering}`}>
        <label>{t.get(translations, "Wagering Requirement")}:</label>
        <span>{getWageringText(casino, translations)}</span>
      </p>
      <a
        target={`_blank`} rel={`noopener noreferrer nofollow`}
        href={r.get(locale, translations, "redirectPage", casino.affiliateMask)} >
        <p className={`play-button ${styles.playButton}`}>{t.get(translations, 'bonus-text')}</p>
      </a>
    </div> : ''
}

/* TODO: move this crap to a helper so that there is no duplicate code (all makeXXXCard functions), portable-text-to-tabs has the same code! :( */
const makeCashbackCard = (casino, bonus, translations, locale) => {
  if (bonus) {
    return <div id={`${addNamedClass(casino, 'tab-bonus-card-deposit')}`} className={`${styles.bonusCard}`}>
      <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-text-deposit')}`} className={`deposit-bonus-text ${styles.depositBonusText}`}>{t.get(translations, 'bonus-text-cashback')}</p>
      {bonus.bonusValue && <p className={`deposit-bonus-amount ${styles.depositBonusAmount}`}>{bonus.bonusValue}%</p>}
      {bonus.type && <p className={`welcome-bonus-text ${styles.welcomeBonusText}`}>{bonus.type.name}</p>}
      <p id={`${addNamedClass(casino, 'tab-bonus-card-cashback-maximum-amount')}`} className={`cashback-bonus-amount ${styles.cashbackBonusAmount}`}>
        {bonus.cashbackIsPerSpin ? <span>per<br/>{t.get(translations, 'per-spin')}</span>  : bonus.maximumBonusAmount ? <span>max<br/>{ bonus.maximumBonusAmount + t.get(translations, 'currency')}</span> : t.get(translations, "bonus-no-max-amount")}</p>
      <p className={`${styles.bonusCardsWagering}`}>
        <label>{t.get(translations, "Wagering Requirement")}:</label>
        <span>{getWageringText(casino, translations)}</span>
      </p>
      <a
        id={`${addNamedClass(casino, 'tab-bonus-card-bonus-cta')}`}
        target={`_blank`} rel={`noopener noreferrer nofollow`}
        href={r.get(locale, translations, "redirectPage", casino.affiliateMask)} >
        <p className={`play-button ${styles.playbutton}`}>{t.get(translations, 'bonus-text')}</p>
      </a>
      {bonus.freespinsAmount && <p className={`free-spins-amount ${styles.freeSpinsAmount}`}>+ {bonus.freespinsAmount} {t.get(translations, 'freespins-tab-card')}</p>}
    </div>
  }
}

const makeBonusCard = (casino, bonus, index, translations, locale) => {
  if (bonus) {
    if (bonus.bonusValue) {
      return <div id={`${addNamedClass(casino, 'tab-bonus-card-' + index)}`} className={`${styles.bonusCard}`}>
        <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-text-' + index)}`} className={`deposit-bonus-text ${styles.depositBonusText}`}>{index}. {t.get(translations, 'bonus-text-deposit')}</p>
        {bonus.bonusValue && <p className={`deposit-bonus-amount ${styles.depositBonusAmount}`}>{bonus.bonusValue}%</p>}
        {bonus.type && <p className={`welcome-bonus-text ${styles.welcomeBonusText}`}>{bonus.type.name}</p>}
        <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-welcome-text-' + index)}`} className={`welcome-bonus-amount ${styles.welcomeBonusAmount}`}>{bonus.maximumBonusAmount}</p>
        <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-currency-' + index)}`} className={`currency ${styles.currency}`}>{t.get(translations, 'currency')}</p>
        <p className={`${styles.bonusCardsWagering}`}>
          <label>{t.get(translations, "Wagering Requirement")}:</label>
          <span>{getWageringText(casino, translations)}</span>
        </p>
        <a
          id={`${addNamedClass(casino, 'tab-bonus-card-bonus-cta-' + index)}`}
          target={`_blank`} rel={`noopener noreferrer nofollow`}
          href={r.get(locale, translations, "redirectPage", casino.affiliateMask)} >
          <p className={`play-button ${styles.playbutton}`}>{t.get(translations, 'bonus-text')}</p>
        </a>
        {bonus.freespinsAmount && <p className={`free-spins-amount ${styles.freeSpinsAmount}`}>+ {bonus.freespinsAmount} {t.get(translations, 'freespins-tab-card')}</p>}
      </div>
    }
    // must be a freespins bonus
    else {
      return <div id={`${addNamedClass(casino, 'tab-bonus-card-bonus-freespin-' + index)}`} className={`${styles.bonusCard}`}>
        <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-text-freespin-' + index)}`} className={`deposit-bonus-text ${styles.depositBonusText}`}>{index}. {t.get(translations, 'bonus-text-deposit')}</p>
        <p className={`deposit-bonus-amount ${styles.depositBonusAmount}`}></p>
        {bonus.type && <p className={`welcome-bonus-text ${styles.welcomeBonusText}`}>{bonus.type.name}</p>}
        {<p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-freespin-welcome-text-' + index)}`} className={`welcome-bonus-amount ${styles.welcomeBonusAmount}`}>{bonus.freespinsAmount}</p>}
        <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-currency-freespin-text-' + index)}`} className={`currency ${styles.currency}`}>{t.get(translations, 'freespins')}</p>
        <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-currenct-freespin-info-text-' + index)}`} className={`free-spins-slot-machines ${styles.freeSpinsSlotMachines}`}>{bonus.relatedGames.slice(0, 3).map((x, index) => x.name + (index < 2 ? ', ' : '...'))}</p>
        <p className={`${styles.bonusCardsWagering}`}>
          <label>{t.get(translations, "Wagering Requirement")}:</label>
          <span>{getWageringText(casino, translations)}</span>
        </p>
        <a
          id={`${addNamedClass(casino, 'tab-bonus-card-bonus-freespin-text-cta-' + index)}`}
          target={`_blank`} rel={`noopener noreferrer nofollow`}
          href={r.get(locale, translations, "redirectPage", casino.affiliateMask)} >
          <p className={`play-button ${styles.playbutton}`}>{t.get(translations, 'bonus-text')}</p>
        </a>
      </div>
    }
  }
  return ''
}

const makeBonusCards = (casino, translations, locale) => {
  return <div className={`${styles.bonusCardsContainer}`}>
    {[makeBonusCard(casino, CasinoHelper.GetFirstDepositBonus(casino), 1, translations, locale),
      makeBonusCard(casino, CasinoHelper.GetSecondDepositBonus(casino), 2, translations, locale),
      makeBonusCard(casino, CasinoHelper.GetThirdDepositBonus(casino), 3, translations, locale),
      makeBonusCard(casino, CasinoHelper.GetFourthDepositBonus(casino), 4, translations, locale),
      makeBonusCard(casino, CasinoHelper.GetFifthDepositBonus(casino), 5, translations, locale),
      makeBonusCard(casino, CasinoHelper.GetSixthDepositBonus(casino), 6, translations, locale),
      makeCashbackCard(casino, CasinoHelper.GetCashbackBonus(casino), translations, locale)
      ]}
  </div>

}

const CasinoInfoBox = ({ casino, translations }) => {

  const TickCross = ({ isTick, casino }) => {
    return (
      <div className={`${styles.tickCross}`}>
        {isTick ?
          <CheckIcon className={`${styles.checkIcon}`} />
         :
          <ClearIcon className={`${styles.crossIcon}`} />
        }
      </div>
    )
  }

  return (
    <div className={`${styles.casinoInfoBox}`}>
      <div className={`${styles.infoItem}`}>
        <label>{t.get(translations, "casino-review-vip-program")}:</label>
        <span>
          <TickCross
            isTick={casino && casino.hasVipProgram}
            casino={casino}
          />
        </span>
      </div>
      <div className={`${styles.infoItem}`}>
        <label>{t.get(translations, "casino-review-bonus-forfeitable")}:</label>
        <span>
          <TickCross
            isTick={casino && casino.isBonusForfeitable}
            casino={casino}
          />
        </span>
      </div>
      <div className={`${styles.infoItem}`}>
        <label>{t.get(translations, "casino-review-bonus-with-e-wallet")}:</label>
        <span>
          <TickCross
            isTick={casino && casino.canClaimWithEWallet}
            casino={casino}
          />
        </span>
      </div>
      <div className={`${styles.infoItem}`}>
        <label>{t.get(translations, "casino-review-max-bet-with-bonus")}:</label>
        <span>{casino.maxBet === "" ? "-" : getMaxBetText(translations, casino.maxBet)}</span>
      </div>
    </div>
  )
}

const getGroups = (items, count, translations, locale, casino, routeName) => {
  return (
    <div className={`${styles.casinoLogoGroups}`}>
      {items &&
        items.filter(x => x.largeIcon && x.largeIcon.asset).map(x => {
          return (
            <span>
              <span
                key={x.name}>
                {x.largeIcon && x.largeIcon.asset && (
                  <img src={x.largeIcon.asset.url} alt={x.displayName || x.name} />
                )}
              </span>
            </span>
          )
        })
      }
    </div>
  )
}

const WithdrawalLimits = ({ casino, locale, translations }) => {
  
  return (
    <div className={`${styles.withdrawalLimitsWrapper}`}>
      <p className={`${styles.title}`}>{t.get(translations, 'withdrawal-limits-cai')}</p>
      <div className={`${styles.withdrawalLimits}`}>
        {casino.extendedData &&
          <p className={`${styles.item}`}>
            <label>{t.get(translations, "casino-review-singular-transaction-withdrawal-limit")}:</label>
          <span>{casino.extendedData.singularTransactionWithdrawalLimit ? casino.extendedData.singularTransactionWithdrawalLimit : "-"}</span>
          </p>
        }
        {casino.extendedData &&
          casino.extendedData.withdrawalLimitsFrequency.map((x, index) => {
            return (
              <p className={`${styles.item}`}>
                <label>{t.get(translations, "casino-review-per-" + x)}:</label>
                <span>
                  {casino.extendedData.withdrawalLimits[index]}{t.get(translations,"currency")}
                </span>
              </p>
            )
          })
        }
        {casino.withdrawalTimes &&
          <p className={`${styles.item}`}>
            <label>{t.get(translations, "casino-review-withdrawal-times")}:</label>
            <span>{casino.withdrawalTimes} {t.get(translations, "casino-review-days")}</span>
          </p>
        }
      </div>
    </div>
  )
}

const GameProviders = ({ casino, locale, translations }) => {
  return (
    <div className={`${styles.ailogoContainer}`}>
      {casino.gameProviders &&
        getGroups(casino.gameProviders, 9, translations, locale, casino, 'gameProvider')}
    </div>
  )
}

const removeDisabledPaymentMethodFilter = (method, currentLocale) => {
  if (method.disabledLocales){
    if (method.disabledLocales.some(x => currentLocale === x)) {
      return false;
    }
  }
  return true;
}


const PaymentMethods = ({ casino, locale, translations }) => {
  return (
    <div className={`${styles.depositWithdrawalContainer}`}>
      <p>{t.get(translations, 'Deposit Methods')}</p>
      <div className={`${styles.ailogoContainer}`}>
        {casino.depositMethods &&
          getGroups(casino.depositMethods.filter(x => removeDisabledPaymentMethodFilter(x, locale)), 4, translations, locale, casino, 'depositMethods')}
      </div>
      <p>{t.get(translations, 'Withdrawal Methods')}</p>
      <div className={`${styles.ailogoContainer}`}>
        {casino.withdrawalMethods &&
          getGroups(casino.withdrawalMethods.filter(x => removeDisabledPaymentMethodFilter(x, locale)), 4, translations, locale, casino, 'withdrawalMethods')}
      </div>
    </div>
  )
}
const hashCode = function(s){
  return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
}

const ContentAi = ({casino, locale, translations, template}) => {
  let currentIndex = 0;
  const view = {
    "styles": styles,
    "locale": locale,
    "fi": locale === 'fi',
    "casino": casino,
    "bold": function () {
      return function(text, render) {
        return "<b>" + render(text) + "</b>"
      }
    },
    "translate": function () {
      return function (text, render) {
        return t.get(translations, text);
      }
    },
    "bonusCards": function () {
      return function () {
        return ReactDOMServer.renderToString(makeBonusCards(casino, translations, locale))
      }
    },
    "freespinCards": function () {
      return function () {
        return ReactDOMServer.renderToString(makeFreespinCards(casino, translations, locale))
      }
    },
    "infoBox": function () {
      return function () {
        return ReactDOMServer.renderToString(<CasinoInfoBox casino={casino} translations={translations} />)
      }
    },
    "gameProviders": function () {
      return function () {
        return ReactDOMServer.renderToString(<GameProviders casino={casino} translations={translations} locale={locale} />)
      }
    },
    "paymentMethods": function () {
      return function () {
        return ReactDOMServer.renderToString(<PaymentMethods casino={casino} translations={translations} locale={locale} />)
      }
    },
    "withdrawalLimits": function () {
      return function () {
        return ReactDOMServer.renderToString(<WithdrawalLimits casino={casino} translations={translations} locale={locale} />)
      }
    },
    "options":function () {
      return function(text) {
        const number = Math.abs(hashCode(casino.name));
        const optArray = text.split('||');
        const digitArray = (""+number).split("");
        const indexedNumberToUse = digitArray[currentIndex % digitArray.length];
        currentIndex++;
        const mod = indexedNumberToUse % (optArray.length - 1);

        return optArray[mod];
      }
    },
    "casinoOverall1": h.casinoAverageRating(casino) < 2,
    "casinoOverall2": () =>  h.casinoAverageRating(casino)  >= 2 &&  h.casinoAverageRating(casino)  < 3,
    "casinoOverall3": () =>  h.casinoAverageRating(casino)  >= 3 &&  h.casinoAverageRating(casino)  < 4,
    "casinoOverall4": () =>  h.casinoAverageRating(casino)  >= 4 &&  h.casinoAverageRating(casino)  < 5,
    "casinoOverall5": () =>  h.casinoAverageRating(casino)  >= 5 &&  h.casinoAverageRating(casino)  < 6,
    "casinoBonus1": () => casino.bonusRating < 2,
    "casinoBonus2": () => casino.bonusRating >= 2 && casino.bonusRating < 3,
    "casinoBonus3": () => casino.bonusRating >= 3 && casino.bonusRating < 4,
    "casinoBonus4": () => casino.bonusRating >= 4 && casino.bonusRating < 5,
    "casinoBonus5": () => casino.bonusRating >= 5 && casino.bonusRating < 6,
    "casinoMobile1": casino.mobileRating < 2,
    "freespinWelcomeBonusSlots": () => {
      const bonus = CasinoHelper.GetTopFirstDepositBonus(casino);
      if (bonus.relatedGames){
        let template = ', to be used across ';
        if (bonus.relatedGames.length === 1){
          template = ', to be used on '
        }
        return template + bonus.relatedGames.map(x => x.name).join(', ');
      }

      return "";
    },
    "casinoMobile2": () => casino.mobileRating >= 2 && casino.mobileRating < 3,
    "casinoMobile3": () => casino.mobileRating >= 3 && casino.mobileRating < 4,
    "casinoMobile4": () => casino.mobileRating >= 4 && casino.mobileRating < 5,
    "casinoMobile5": () => casino.mobileRating >= 5 && casino.mobileRating < 6,
    "casinoGames1": casino.gameVarietyRating < 2,
    "casinoGames2": () => casino.gameVarietyRating >= 2 && casino.gameVarietyRating < 3,
    "casinoGames3": () => casino.gameVarietyRating >= 3 && casino.gameVarietyRating < 4,
    "casinoGames4": () => casino.gameVarietyRating >= 4 && casino.gameVarietyRating < 5,
    "casinoGames5": () => casino.gameVarietyRating >= 5 && casino.gameVarietyRating < 6,
    "casinoSupport1": casino.customerServiceRating < 2,
    "casinoSupport2": () => casino.customerServiceRating >= 2 && casino.customerServiceRating < 3,
    "casinoSupport3": () => casino.customerServiceRating >= 3 && casino.customerServiceRating < 4,
    "casinoSupport4": () => casino.customerServiceRating >= 4 && casino.customerServiceRating < 5,
    "casinoSupport5": () => casino.customerServiceRating >= 5 && casino.customerServiceRating < 6,
    "casinoBanking1": casino.transferRating < 2,
    "casinoBanking2": () => casino.transferRating >= 2 && casino.transferRating < 3,
    "casinoBanking3": () => casino.transferRating >= 3 && casino.transferRating < 4,
    "casinoBanking4": () => casino.transferRating >= 4 && casino.transferRating < 5,
    "casinoBanking5": () => casino.transferRating >= 5 && casino.transferRating < 6,
    "gamingOptionsList": function() {
      let items = [];
      if (casino.blackjack)
        items.push('blackjack')
      if (casino.roulette)
        items.push('roulette')
      if (casino.videoPoker)
        items.push('videoPoker')
      if (casino.extendedData && casino.extendedData.hasBaccarat)
        items.push('hasBaccarat')
      if (casino.extendedData && casino.extendedData.hasCraps)
        items.push('hasCraps')
      if (casino.bingo)
        items.push('bingo')
      if (casino.hasLotto)
        items.push('hasLotto')
      if (casino.hasScratchcards)
        items.push('hasScratchcards')
      if (casino.keno)
        items.push('keno')
      if (casino.hasSportsbook)
        items.push('hasSportsbook')

      return function() {
        return ReactDOMServer.renderToString(<ul>
        {items.map(x => t.get(translations, x + '-contentai-list')).map(x => <li>{x}</li>)}
      </ul>) };
    },
    "responsibleGamingList": function() {
      let items = [];
      if (casino.extendedData){
        if (casino.extendedData.hasTimeOutPeriod) {
          items.push('hasTimeOutPeriod');
        }
        if (casino.extendedData.hasSelfExclusion) {
          items.push('hasSelfExclusion')
        }
        if (casino.extendedData.hasSelfAssessmentTest) {
          items.push('hasSelfAssessmentTest')
        }

        if (casino.extendedData.hasOnlineTransactionHistory) {
          items.push('hasOnlineTransactionHistory')
        }

        if (casino.extendedData.hasFinancialLimits) {
          items.push('hasFinancialLimits')
        }

        if (casino.extendedData.hasDirectLinkToHelpOrganisation) {
          items.push('hasDirectLinkToHelpOrganisation')
        }

        if (casino.extendedData.realityCheckReminder) {
          items.push('realityCheckReminder')
        }
      }

      return function() {
        return ReactDOMServer.renderToString(<ul>
          {items.map(x => t.get(translations, x + '-contentai-list')).map(x => <li>{x}</li>)}
      </ul>)
      };
    },
    "casinoResponsibleGaming1": casino.extendedData ? casino.extendedData.responsibleGamingRating < 2 : true,
    "casinoResponsibleGaming2": casino.extendedData ? casino.extendedData.responsibleGamingRating < 3 : false,
    "casinoResponsibleGaming3": casino.extendedData ? casino.extendedData.responsibleGamingRating < 4 : false,
    "casinoResponsibleGaming4": casino.extendedData ? casino.extendedData.responsibleGamingRating < 5 : false,
    "casinoResponsibleGaming5": casino.extendedData ? casino.extendedData.responsibleGamingRating < 6 : false,
    "gameProviderCount": casino.gameProviders ? casino.gameProviders.length : 0,
    "welcomeBonusWageringText": () => {
      const wb = CasinoHelper.GetTopFirstDepositBonus(casino);
      if (wb){
        if (wb.wageringRequirement)
          return wb.wageringRequirement + wb.wageringRequirementType;

        return "0x"
      }

      return ""
    } ,
    "welcomeBonus":  CasinoHelper.GetTopFirstDepositBonus(casino),
    "hasWelcomeBonus": CasinoHelper.GetTopFirstDepositBonus(casino) !== null,
    "noDepositBonus": CasinoHelper.GetNoDepositBonus(casino),
    "hasNoDepositBonus": CasinoHelper.GetNoDepositBonus(casino) !== null,
    "noDepositFreespinBonus": CasinoHelper.GetFreespinNoDepositBonus(casino),
    "hasNoDepositFreespinBonus": CasinoHelper.GetFreespinNoDepositBonus(casino) !== null,
    "noDepositFreespinAmount": CasinoHelper.GetFreespinNoDepositBonus(casino) !== null ? CasinoHelper.GetFreespinNoDepositBonus(casino).freespinsAmount : 0,
    "cashbackBonus": CasinoHelper.GetCashbackBonus(casino),
    "hasCashbackBonus": CasinoHelper.GetCashbackBonus(casino) !== null,
    "secondDepositBonus": CasinoHelper.GetSecondDepositBonus(casino),
    "hasSecondDepositBonus": CasinoHelper.GetSecondDepositBonus(casino) !== null,
    "thirdDepositBonus": CasinoHelper.GetThirdDepositBonus(casino),
    "hasThirdDepositBonus": CasinoHelper.GetThirdDepositBonus(casino) !== null,
    "fourthDepositBonus": CasinoHelper.GetFourthDepositBonus(casino),
    "hasFourthDepositBonus": CasinoHelper.GetFourthDepositBonus(casino) !== null,
    "fifthDepositBonus":  CasinoHelper.GetFifthDepositBonus(casino),
    "hasFifthDepositBonus": CasinoHelper.GetFifthDepositBonus(casino) !== null,
    "hasTwoBonusPackage": CasinoHelper.GetSecondDepositBonus(casino) !== null && CasinoHelper.GetThirdDepositBonus(casino) == null,
    "hasThreeBonusPackage": CasinoHelper.GetSecondDepositBonus(casino) !== null && CasinoHelper.GetThirdDepositBonus(casino) !== null && CasinoHelper.GetFourthDepositBonus(casino) == null,
    "hasFourBonusPackage": CasinoHelper.GetSecondDepositBonus(casino) !== null && CasinoHelper.GetThirdDepositBonus(casino) !== null && CasinoHelper.GetFourthDepositBonus(casino) !== null,
    "bonusPackTotal": () => {
      const fdb = CasinoHelper.GetTopFirstDepositBonus(casino);
      const sdb = CasinoHelper.GetSecondDepositBonus(casino);
      const tdb = CasinoHelper.GetThirdDepositBonus(casino);
      const fourthDepositBonus = CasinoHelper.GetFourthDepositBonus(casino);

      let total = 0;
      if (fdb)
        total += fdb.maximumBonusAmount;
      if (sdb)
        total += sdb.maximumBonusAmount;
      if (tdb)
        total += tdb.maximumBonusAmount
      if (fourthDepositBonus)
        total += fourthDepositBonus.maximumBonusAmount;

      return total;
    },
    "isWelcomeBonusOnlyFreespins": () => {
      const bonus = CasinoHelper.GetTopFirstDepositBonus(casino)
      if (bonus && bonus.bonusValue <= 0 && bonus.maximumBonusAmount <= 0 && bonus.freespinsAmount > 0) {
        return true;
      }
      return false;
    },
    "isWelcomeBonusOnlyCash": () => {
      const bonus = CasinoHelper.GetTopFirstDepositBonus(casino)
      if (bonus && bonus.bonusValue > 0 && bonus.maximumBonusAmount > 0 && bonus.freespinsAmount <= 0) {
        return true;
      }
      return false;
    },
    "isWelcomeBonusCashAndFreespins": () => {
      const bonus = CasinoHelper.GetTopFirstDepositBonus(casino)
      if (bonus && bonus.bonusValue > 0 && bonus.maximumBonusAmount > 0 && bonus.freespinsAmount > 0) {
        return true;
      }
      return false;
    }

  };
  return <div className={`${styles.partsContainer}`} dangerouslySetInnerHTML={ { __html: mustache.render(template, view)} } />
 }

 export default ContentAi;