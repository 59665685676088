import React, { useContext, useState, useEffect } from "react"
import Img from "gatsby-image"

import { graphql, Link } from "gatsby"
import styles from "./item.module.scss"
import Swiper from "react-id-swiper"
import "../../../public/styles/swiper.min.css"
import {
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Tabs,
  Tab,
  NativeSelect,
  Typography,
} from "@material-ui/core"
import LinkIcon from '@material-ui/icons/Link';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Rating from "@material-ui/lab/Rating"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"
import Layout from "../../components/layout"
import PortableTextToTabs from "../../components/text-helpers/portable-text-to-tabs"
import h from "../../services/GenericHelpers"
import NullIf from "../../services/NullIf"
import CasinoHelper from "../../services/casino-bonus-helpers"
import ThemeContext from "../../context/ThemeContext"
import t from "../../services/GetTranslationValue"
import r from "../../services/Router"
import check from "../../../static/icons/check-review.svg"
import cross from "../../../static/icons/cancel.png"
import SEO from "../../components/seo"
import localize from "../../components/localize"
import { getLocaleAlias, localizeItem } from "../../graphql-assistant/createLocaleTextGetter"
import * as PropTypes from "prop-types"
import ContentAi from "./content-ai"
import { getMaxBetText } from "../../services/casino-property-renders"
import LineBonusCard from "../../components/bonuses/line-bonus-card"
import InfoIcon from '@material-ui/icons/Info';
import BlockIcon from '@material-ui/icons/Block';
import Comments from "../../components/comments/comments"
import { API_ROOT } from "gatsby-env-variables"
import { fetchUserReviews } from "../../api/client/userReviews"
import Slider from "../../components/slider/slider"

const addNamedClass = (casino, suffix) => {
  if (casino.name === 'Rizk')
    return casino.name + '-' + suffix;

  return '';
}

const CasinoDepAndWith = ({ casino, translations, locale }) => {

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={depWithTabValue !== index}
        id={`horizontal-tabpanel-${index}`}
        aria-labelledby={`horizontal-tab-${index}`}
        {...other}
      >
        {children}
      </Typography>
    )
  }

  function TabsIndex(index) {
    return {
      id: `horizontal-tab-${index}`,
      "aria-controls": `horizontal-tabpanel-${index}`,
    }
  }

  const [depWithTabValue, setDepWithTabValue] = useState(0)

  const depWithTabHandler = (event, newValue) => {
    setDepWithTabValue(newValue)
  }

  return (
    <div className={`${styles.depAndWith}`}>
      {(casino.withdrawalMethods && casino.depositMethods) && <Tabs
        orientation="horizontal"
        value={depWithTabValue}
        onChange={depWithTabHandler}
        indicatorColor="primary"
        textColor="primary"
        className={`gamble-tab ${styles.tabs}`}
      >
        <Tab
          label={t.get(translations, 'deposit-methods')}
          {...TabsIndex(0)}
          className={`${styles.tab}`}
        />
        <Tab
          label={t.get(translations, 'withdrawal-methods')}
          {...TabsIndex(1)}
          className={`${styles.tab}`}
        />
      </Tabs>}
      <TabPanel
        value={depWithTabValue}
        index={0}
        className={`${styles.tabContent}`}
      >
        <CasinoDepositMethodes translations={translations} casino={casino} locale={locale} />
      </TabPanel>
      <TabPanel
        value={depWithTabValue}
        index={1}
        className={`${styles.tabContent}`}
      >
        <CasinoWithdrawalMethodes translations={translations} casino={casino} locale={locale} />
      </TabPanel>
    </div>
  )
}

const CasinoLicenseWarning = ({ casino, locale, translations }) => {
  const suggestions = [
    casino,
    casino,
    casino,
    casino
  ];

  return (
    <div className={`${styles.casinoLicenseWarning}`}>
      <p className={`${styles.warningTitle}`}>
        <InfoIcon />
        {casino.name} {t.get(translations, 'casino-review-license-warning')}:
      </p>
      <div className={`${styles.suggestions}`}>
        {suggestions.map((x, index) => {
          return (
            <LineBonusCard
              casino={x}
              index={index}
              locale={locale}
              // forceSports={sportsBook}
              // promotionalText={promotionalText}
              // isRaffle={isRaffle}
              // forceTopBonus={forceTopBonus}
              // isSelected={true}
              // forceSpecificBonusType={forceSpecificBonusType}
              // showBonusCode={showBonusCode}
            />
          )
        })}
      </div>
    </div>
  )
}

const CasinoReviewTitle = ({ casino, review, translations }) => {
  return (
    <h1 className={`${styles.mainTitle}`}>
      {(review.header)
        ? review.header
        : (casino.name + ' ' + t.get(translations, 'review-text-header-template'))}
    </h1>
  )
}

const UserReviewsAverage = ({ casino, userRatings, reviews, translations }) => {
  const userAvrg = () => {
    let total = 0;
    reviews.comments.map(x => {
      total = total + parseFloat(x.rating)
    })
    return `${(total / reviews.comments.length).toString().slice(0, 4)} `
  }

  return (
    <div className={`${styles.usersRating}`}>
      <p className={`${styles.label}`}>{t.get(translations, "casino-review-users-reviews")} : </p>
      <div className={`${styles.userReviewsCountWrapper}`}>
        <p className={`${styles.value}`}><span>{reviews && reviews.comments && reviews.comments.length > 0 ? userAvrg() : userRatings && userRatings.rating_avg}</span>/ 5.0{" "}</p>
        <p className={`${styles.reviewsCount}`}>({reviews ? reviews.comments && reviews.comments.length : userRatings && userRatings.review_count} {t.get(translations, 'user-reviews-summary-number')})</p>
      </div>
    </div>
  )
}

const getSummaryText = (casino, translations) => {
  let fdb = CasinoHelper.getBonusToUse(casino, false)

  if (fdb && fdb.bonusValue)
    return `${fdb.bonusValue}% / ${fdb.maximumBonusAmount}${t.get(
      translations,
      "currency"
    )}`

  return "-"
}

const getFreeSpinsText = casino => {
  let freespinNoDepositBonus = CasinoHelper.GetFreespinNoDepositBonus(casino)
  if (freespinNoDepositBonus) return freespinNoDepositBonus.freespinsAmount

  return "-"
}

const getWageringText = (casino, translations) => {
  let bonus = CasinoHelper.getBonusToUse(casino, false)
  if (bonus && bonus.wageringRequirement && bonus.wageringRequirementType)
    return `${bonus.wageringRequirement}${t.get(translations, bonus.wageringRequirementType)}`

  if (!bonus)
    return '-'

  return "0x"
}

const getBonusCodeText = casino => {
  let bonus = CasinoHelper.getBonusToUse(casino, false)
  if (bonus && bonus.bonusCode) return bonus.bonusCode

  return "-"
}

const CasinoPubDates = ({ translations, casino, review }) => {
  return (
    <div className={`${styles.reviewTopDates}`}>
      <div className={`${styles.publishDate}`}>
        <label>{t.get(translations, 'casino-review-review-published')}: </label>
        <span>{review._createdAt && review._createdAt.slice(0, 10)}</span>
      </div>
      <div className={`${styles.updateDate}`}>
        <label>{t.get(translations, 'casino-review-updated-at')}: </label>
        <span>{casino._updatedAt && casino._updatedAt.slice(0, 10)}</span>
      </div>
    </div>
  )
}

const CasinoDetails = ({ locale, translations, casino }) => {

  const getFreespins = (casino, locale, translations) => {
    const z = [].concat
      .apply([], casino.bonuses)
      .map(x => x.relatedGames)

    const result = [].concat.apply([], z);
    return result.filter(x => x).map(x => x.name).filter(distinct).slice(0, 4).map(slot =>
      <Link id={`${addNamedClass(casino, 'review-slot-link-') + slot}`} to={r.get(locale, translations, "slotBonus", slot)}>{slot}</Link>);
  }

  const distinct = (value, index, self) => {
    return self.indexOf(value) === index
  }

  return (
    <div id={`${addNamedClass(casino, 'review-details-about-casino')}`} className={`${styles.casinoDetails}`}>
      <p id={`${addNamedClass(casino, 'review-details-about-website')}`} className={`${styles.website}`}>{`${t.get(translations, 'website')}: `}
        <a
          id={`${addNamedClass(casino, 'review-details-for-website')}`}
          target={`_blank`} rel={`noopener noreferrer nofollow`}
          href={r.get(locale, translations, "redirectPage", casino.affiliateMask)}>
          {casino.website}
        </a>
      </p>
      <p id={`${addNamedClass(casino, 'review-details-for-support')}`} className={`${styles.mail}`}>{t.get(translations, 'support-email')}: {casino.supportEmail}</p>
      <p id={`${addNamedClass(casino, 'review-details-telephone-number')}`} className={`${styles.phone}`}>{t.get(translations, 'support-phone')}: {casino.telephoneNumber}</p>
      <p id={`${addNamedClass(casino, 'review-details-established-casino')}`} className={`${styles.date}`}>{t.get(translations, 'date-established')} :{" "}
        <Link
          id={`${addNamedClass(casino, 'review-established-linked-data')}`}
          to={r.get(
            locale,
            translations,
            "casinoEstablished",
            casino.dateEstablished,
            true
          )}
        >
          {casino.dateEstablished}
        </Link>
      </p>
      {casino.platform && casino.platform.name &&
        <p id={`${addNamedClass(casino, 'review-casino-platform')}`} className={`${styles.website}`}>{t.get(translations, 'platform')}:
          <Link
            id={`${addNamedClass(casino, 'review-casino-platform')}`}
            to={r.get(
              locale,
              translations,
              "platform",
              casino.platform.name,
            )}
          >
            {casino.platform.name}
          </Link>
        </p>
      }
      <p id={`${addNamedClass(casino, 'review-rating-group-data')}`} className={`${styles.group}`}>{t.get(translations, 'casino-group')}: {casino.group && <Link id={`${addNamedClass(casino, 'review-rating-group-link-data')}`} to={r.get(locale, translations, "casinoGroup", casino.group.name)}>{casino.group.name}</Link>}</p>
      <p id={`${addNamedClass(casino, 'review-licence-information')}`} className={`${styles.licences}`}>{t.get(translations, 'licences')}:
        {casino.licences &&
        casino.licences.map(({ name }, index) => {
          return (
            <span id={`${addNamedClass(casino, 'review-licence-' + name)}`}>
                {index > 0 ? "," : ""}
              <Link id={`${addNamedClass(casino, 'review-licence-link' + name)}`} to={r.get(locale, translations, "licence", name)}>
                  {" "}
                {name}
                </Link>
              </span>
          )
        })}
      </p>
      {locale.startsWith("en") &&
      <p id={`${addNamedClass(casino, 'review-languages-information')}`} className={`${styles.languages}`}>{t.get(translations, 'languages')}:
        {
          casino.languagesOffered && casino.languagesOffered.map(({ name }, index) => <span className={`${styles.languageItem}`}>
              {index > 0 ? "," : ""}
            <Link id={`${addNamedClass(casino, 'review-lanaguages-offered-link' + name)}`} to={r.get(locale, translations, "casinoLanguage", name)}>
                {" "}
              {name}
              </Link>
            </span>)
        }
      </p>
      }
    </div>
  )
}

const AllRatings = ({ casino, translations }) => {
  return (
    <div className={`${styles.ratingsContent}`}>
      <div id={`${addNamedClass(casino, 'review-rating-data-header-conta2')}`} className={`${styles.rateItem}`}>
        <label>{t.get(translations, "Bonus")}:</label>
        <p
          id={`${addNamedClass(casino, 'review-rating-data-header-bonusrating')}`}
          className={`${(Math.round(casino.bonusRating / 0.5) * 0.5) % 1 !== 0 ? styles.halfRate: ""}`}
        >
          <Rating
            readOnly={true}
            name="half-rating"
            emptyIcon={<span className={`${styles.emptyChip}`} />}
            icon={<span className={`${styles.fullChip}`} />}
            size="small"
            value={casino.bonusRating}
            precision={0.5}
          />
        </p>
      </div>
      <div id={`${addNamedClass(casino, 'review-rating-data-header-usarating')}`} className={`${styles.rateItem}`}>
        <label>{t.get(translations, "Usability")}:</label>
        <p
          id={`${addNamedClass(casino, 'review-rating-data-header-usarating-data')}`}
          className={`${(Math.round(casino.usabilityRating / 0.5) * 0.5) % 1 !== 0 ? styles.halfRate : ""}`}
        >
          <Rating
            readOnly={true}
            name="half-rating"
            emptyIcon={<span className={`${styles.emptyChip}`} />}
            icon={<span className={`${styles.fullChip}`} />}
            size="small"
            value={casino.usabilityRating}
            precision={0.5}
          />
        </p>
      </div>
      <div id={`${addNamedClass(casino, 'review-rating-data-cs-rating')}`} className={`${styles.rateItem}`}>
        <label>{t.get(translations, "Customer Service")}:</label>
        <p
          id={`${addNamedClass(casino, 'review-rating-data-header-usratingdata')}`}
          className={`${(Math.round(casino.customerServiceRating / 0.5) * 0.5) % 1 !== 0 ? styles.halfRate : ""}`}
        >
          <Rating readOnly={true}
                  name="half-rating"
                  emptyIcon={<span className={`${styles.emptyChip}`} />}
                  icon={<span className={`${styles.fullChip}`} />}
                  size="small"
                  value={casino.customerServiceRating}
                  precision={0.5}
          />
        </p>
      </div>
      <div id={`${addNamedClass(casino, 'review-rating-data-games')}`} className={`${styles.rateItem}`}>
        <label id={`${addNamedClass(casino, 'review-rating-data-header-games-label')}`}>{t.get(translations, "Games")}:</label>
        <p
          id={`${addNamedClass(casino, 'review-rating-data-header-info')}`}
          className={`${(Math.round(casino.gameVarietyRating / 0.5) * 0.5) % 1 !== 0 ? styles.halfRate : ""}`}
        >
          <Rating
            readOnly={true}
            name="half-rating"
            emptyIcon={<span className={`${styles.emptyChip}`} />}
            icon={<span className={`${styles.fullChip}`} />}
            size="small"
            value={casino.gameVarietyRating}
            precision={0.5}
          />
        </p>
      </div>
      <div id={`${addNamedClass(casino, 'review-rating-data-transfer')}`} className={`${styles.rateItem}`}>
        <label id={`${addNamedClass(casino, 'review-rating-data-transfer-label')}`}>{t.get(translations, "Transfer")}:</label>
        <p
          id={`${addNamedClass(casino, 'review-rating-data-rating-label')}`}
          className={`${(Math.round(casino.transferRating / 0.5) * 0.5) % 1 !== 0 ? styles.halfRate : ""}`}
        >
          <Rating readOnly={true}
            name="half-rating"
            emptyIcon={<span className={`${styles.emptyChip}`} />}
            icon={<span className={`${styles.fullChip}`} />}
            size="small"
            value={casino.transferRating}
            precision={0.5}
          />
        </p>
      </div>
      <div id={`${addNamedClass(casino, 'review-rating-data-header-mobile')}`} className={`${styles.rateItem}`}>
        <label id={`${addNamedClass(casino, 'review-rating-data-label')}`}>{t.get(translations, "Mobile")}:</label>
        <p
          id={`${addNamedClass(casino, 'review-rating-data-data')}`}
          className={`${(Math.round(casino.mobileRating / 0.5) * 0.5) % 1 !== 0 ? styles.halfRate : ""}`}
        >
          <Rating
            readOnly={true}
            name="half-rating"
            emptyIcon={<span className={`${styles.emptyChip}`} />}
            icon={<span className={`${styles.fullChip}`} />}
            size="small"
            value={casino.mobileRating}
            precision={0.5}
          />
        </p>
      </div>
      <div id={`${addNamedClass(casino, 'review-rating-data-header-mobile')}`} className={`${styles.rateItem}`}>
        <label id={`${addNamedClass(casino, 'review-rating-data-label')}`}>{t.get(translations, "casino-review-responsible-gaming")}:</label>
        <p
          id={`${addNamedClass(casino, 'review-rating-data-data')}`}
          className={`${(Math.round(0 / 0.5) * 0.5) % 1 !== 0 ? styles.halfRate : ""}`}
        >
          <Rating
            readOnly={true}
            name="half-rating"
            emptyIcon={<span className={`${styles.emptyChip}`} />}
            icon={<span className={`${styles.fullChip}`} />}
            size="small"
            value={h.getResponsibleGamingRating(casino)}
            precision={0.5}
          />
        </p>
      </div>
    </div>
  )
}


const CasinoDataTable = ({ smallLogo, name, translations, userRatings, casino, minimumDeposit, maxBet, summary, wageringText, amountOfSlots, reviews}) => {
  return <table className={`${styles.casinoInfo}`}>


    <thead id={``}>
    <tr>
      <td>
        {smallLogo && smallLogo.asset &&
        <div
          className={`${styles.bannerLogo} ${casino.extendedData && casino.extendedData.closed === true ? styles.closedCasino : ""}`}>
          <img
            style={{ width: 100, height: 100, borderRadius: '50%' }}
            srcSet={smallLogo.asset.fluid.srcSet}
            src={smallLogo.asset.fluid.src}
            alt={casino.name}
          />
        </div>
        }
        <span className={`${styles.caption}`}>
          <p className={`${styles.casinoName}`}>{name}</p>
        </span>
        <div className={`gamble-hidden-md-up ${styles.mobileRatingWrapper}`}>
          <div className={`${styles.topRating}`}>
            <p className={`${styles.label}`}>{t.get(translations, "casino-review-overall-rating")} : </p>
            <p className={`${styles.value}`}><span>{h.casinoAverageRating(casino)}</span> / 5.0{" "}</p>
          </div>
          <UserReviewsAverage userRatings={userRatings} casino={casino} reviews={reviews} translations={translations} />
        </div>
      </td>
    </tr>
      {/* <td>
          {smallLogo && smallLogo.asset &&
          <div className={`${styles.bannerLogo} ${casino.extendedData && casino.extendedData.closed === true ? styles.closedCasino : ""}`}>
            <img
              style={{ width: 100, height: 100, borderRadius: '50%' }}
              srcset={smallLogo.asset.fluid.srcSet}
              src={smallLogo.asset.fluid.src}
              alt={casino.name}
            />
          </div>

        }
        <span className={`${styles.caption}`}>
          <p className={`${styles.casinoName}`}>{name}</p>
          <div className={`gamble-hidden-md-up ${styles.topRating}`}>
            <p className={`${styles.label}`}>{t.get(translations, "casino-review-overall-rating")} : </p>
            <p className={`${styles.value}`}><span>{h.casinoAverageRating(casino)}</span> / 5.0{" "}</p>
          </div>
                  <UserReviewsAverage casino={casino} reviews={reviews} translations={translations}/>

        </span>
      </td> */}
    </thead>
    <tbody className={`${styles.rowContainer}`}>
          <tr>
            <td>{t.get(translations, "casino-review-minimum-deposit")}: </td>
            <td>{minimumDeposit}{t.get(translations, 'currency')}</td>
          </tr>
          <tr>
            <td>{t.get(translations, "casino-review-max-bet")}: </td>
            <td>{getMaxBetText(translations, maxBet)}</td>
          </tr>
          <tr>
            <td>{t.get(translations, "casino-review-1st-deposit-bonus")}: </td>
            <td>{summary}</td>
          </tr>
          <tr>
            <td>{t.get(translations, "casino-review-wagering-requirements")}: </td>
            <td>{wageringText}</td>
          </tr>
          <tr>
            <td>{t.get(translations, "casino-review-number-of-slots")}: </td>
            <td>{amountOfSlots}</td>
          </tr>
        </tbody>
  </table>
}

const FloatingInfoCard = ({ casino, translations, userRatings, locale, reviews }) => {

  const summary = getSummaryText(casino, translations)
  const freeSpins = getFreeSpinsText(casino)
  const wageringText = getWageringText(casino, translations)
  const bonusCodeText = getBonusCodeText(casino)

  return (
    <div className={`gamble-hidden-lg-down ${styles.floatingInfoCard}`}>
      <CasinoDataTable userRatings={userRatings} smallLogo={casino.smallLogo} name={casino.name} translations={translations} casino={casino}
                       minimumDeposit={casino.minimumDeposit} maxBet={casino.maxBet} summary={summary}
                       wageringText={wageringText} amountOfSlots={casino.amountOfSlots} reviews={reviews}/>
      <CTAArea casino={casino} locale={locale} translations={translations} params={casino.affiliateMask}/>
    </div>
  )
}

const CTAArea = ({casino, locale, translations, params}) => {

  return <div className={`${styles.ctaParent}`}>
    <a
      target={`_blank`} rel={`noopener noreferrer nofollow`}
      id={`${addNamedClass(casino, "play-now-redirect")}`}
      href={r.get(locale, translations, "redirectPage", params)}
      className={`${styles.casinoCTA} ${casino.extendedData && casino.extendedData.closed === true ? styles.casinoClosed : ""}`}
    >
      <Button
        variant="contained"
        color="primary"
        id={`${addNamedClass(casino, "play-now-button")}`}
        disabled={casino.extendedData && casino.extendedData.closed === true}
      >
        <span
          id={`${addNamedClass(casino, "play-now-button-text")}`}>
            {casino.extendedData && casino.extendedData.closed === true ?
            <span className= {`${styles.ctaCasinoClosed}`}>
              <BlockIcon />
              {t.get(translations, 'casino-closed')}!
            </span>
            :
              t.get(translations, "casino-review-play")
            }
        </span>
      </Button>
    </a>
    {locale === "enGB" &&
      <p className={`${styles.warnings}`}>
        <span>{t.get(translations, "casino-review-gamble-responsively")} | </span>
        <a target={`_blank`} rel={`noopener noreferrer nofollow`} href="http://gambleaware.org/">gambleaware.org</a>
        <span> | +18</span>
        <span
          className={`${styles.termsAndConditions}`}
          id={`${addNamedClass(casino, "terms-and-conditions")}`}
        >
          <a
            target={`_blank`}
            rel={`noopener noreferrer nofollow`}
            href={casino.casinoTermsLink ? casino.casinoTermsLink : r.get(locale, translations, "redirectPage", params)}
          >
            {`${t.get(translations, "terms-and-conditions")} apply - New customers only`}
          </a>
        </span>
      </p>
    }
  </div>
}
const MainBanner = ({ casino, translations, locale, reviews }) => {

  const summary = getSummaryText(casino, translations)
  const freeSpins = getFreeSpinsText(casino)
  const wageringText = getWageringText(casino, translations)
  const bonusCodeText = getBonusCodeText(casino)

  return (
    <section className={`${styles.mainBanner}`}>
      <CasinoDataTable smallLogo={casino.smallLogo} name={casino.name} translations={translations} casino={casino}
                       minimumDeposit={casino.minimumDeposit} maxBet={casino.maxBet} summary={summary}
                       wageringText={wageringText} amountOfSlots={casino.amountOfSlots} reviews={reviews}/>

      <div className={`gamble-hidden-lg-down ${styles.findMe}`}>
        <ResponsibleGaming translations={translations} casino={casino} locale={locale}/>
      </div>
      <div className={`gamble-hidden-lg-down ${styles.casinoFeatures}`}>
        <h2 id={`${addNamedClass(casino, 'review-rating-data-header-2')}`}
            className={`${styles.title}`}>{t.get(translations, "casino-review-casino-features")}</h2>
        <TickCrossAreaGroup translations={translations} casino={casino} locale={locale}/>
      </div>
      <div className={`${styles.ratingCtaContainer}`}>
        <div className={`${styles.ratingParent}`}>
          <div className={`${styles.overalRating}`}>
            <p className={`${styles.label}`}>{t.get(translations, "casino-review-overall-rating")} : </p>
            <p className={`${styles.value}`}><span>{h.casinoAverageRating(casino)}</span>/ 5.0{" "}</p>
          </div>
          <UserReviewsAverage casino={casino} reviews={reviews} translations={translations} />
        </div>
        <CTAArea casino={casino} locale={locale} translations={translations} params={casino.affiliateMask}/>
      </div>
      <div className={`gamble-hidden-lg-up ${styles.featuresAndResponsible}`}>
        <ResponsibleGaming translations={translations} casino={casino} locale={locale}/>
        <div className={`${styles.casinoFeatures}`}>
          <h2 id={`${addNamedClass(casino, 'review-rating-data-header-2')}`} className={`${styles.title}`}>{t.get(translations, "casino-review-casino-features")}</h2>
          <TickCrossAreaGroup translations={translations} casino={casino} locale={locale}/>
        </div>
      </div>
    </section>
  )
}

const CasinoSideBar = ({ casino, translations, locale }) => {

  return (
    <section className={`${styles.sideBar}`}>
      <div className={`${styles.allRatings}`}>
        <h2 id={`${addNamedClass(casino, 'review-rating-data-header-2')}`} className={`${styles.title}`}>{t.get(translations, "rating")}</h2>
        <AllRatings casino={casino} locale={locale} translations={translations} />
      </div>
      <div className={`${styles.casinoBasicInfo}`}>
        <h2 id={`${addNamedClass(casino, 'review-rating-data-header-2')}`} className={`${styles.title}`}>{t.get(translations, "casino-review-casino-info")}</h2>
        <CasinoDetails casino={casino} locale={locale} translations={translations} />
      </div>
    </section>
  )
}

const ResponsibleGaming = ({ casino, translations, locale }) => {
  return (
    <div className={`${styles.responsibleGaming}`}>
      <h2 id={`${addNamedClass(casino, 'review-rating-data-header-2')}`} className={`${styles.title}`}>{t.get(translations, "casino-review-responsible-gaming")}</h2>
      <div className={`${styles.tickCrossContainer}`}>
        <TickCrossArea
          isTick={casino.extendedData && casino.extendedData.hasOnlineTransactionHistory}
          title={t.get(translations, "casino-review-has-online-transaction-history")}
          url={"#"}
          casino={casino}
        />
        <TickCrossArea
          isTick={casino.extendedData && casino.extendedData.hasSelfAssessmentTest}
          title={t.get(translations, "casino-review-has-self-assessment-test")}
          url={"#"}
          casino={casino}
        />
        <TickCrossArea
          isTick={casino.extendedData && casino.extendedData.realityCheckReminder}
          title={t.get(translations, "casino-review-reality-check-reminder")}
          url={"#"}
          casino={casino}
        />
        <TickCrossArea
          isTick={casino.extendedData && casino.extendedData.hasTimeOutPeriod}
          title={t.get(translations, "casino-review-has-time-out-period")}
          url={"#"}
          casino={casino}
        />
        <TickCrossArea
          isTick={casino.extendedData && casino.extendedData.hasFinancialLimits}
          title={t.get(translations, "casino-review-has-financial-limits")}
          url={"#"}
          casino={casino}
        />
        <TickCrossArea
          isTick={casino.extendedData && casino.extendedData.hasSelfExclusion}
          title={t.get(translations, "casino-review-has-self-exclusion")}
          url={"#"}
          casino={casino}
        />
        <TickCrossArea
          isTick={casino.extendedData && casino.extendedData.hasDirectLinkToHelpOrganisation}
          title={t.get(translations, "casino-review-has-direct-link-to-help-organisation")}
          url={"#"}
          casino={casino}
        />
      </div>
    </div>
  )
}

const CasinoGameTypes = ({ casino, translations, locale }) => {

  const gameTypes = [
    ["keno", 'hasKeno', "keno"],
    ["roulette", 'hasRoulette', "roulette"],
    ["hasScratchcards", 'hasScratchcards', "scratchCard"],
    ["blackjack", 'hasBlackjack', "blackJack"],
    ["videoPoker", 'hasVideoPoker', "videoPoker"],
    ["hasLiveCasino", 'liveCasino', "liveGames"],
    ["hasBingo", 'hasBingo', "bingo"],
    ["hasSportsbook", 'hasSportsbook', "sportsbook"],
  ];
  const extendedDataTypes = [
    ["hasBaccarat", 'hasBaccarat', "baccarat"],
    ["hasCraps", 'hasCraps', "craps"],
  ];

  const getItemIcons = (casino, x) => {
    return [casino[x[0]] ?
      <img alt={t.get(translations, `game-provider-details-${x[2].replace(/[A-Z]/, "-$&").toLowerCase()}`)} src={`/../../../icons/game-provider/normal/${x[2]}.svg`} />
      :
      <img alt={t.get(translations, `game-provider-details-${x[2].replace(/[A-Z]/, "-$&").toLowerCase()}`)} src={`/../../../icons/game-provider/disabled/${x[2]}.svg`} />
      ,
      <span className={`${casino[x[0]] ? "" : styles.notAvailable}`}>
      {t.get(translations, `game-provider-details-${x[2].replace(/[A-Z]/, "-$&").toLowerCase()}`)}
    </span>]
  }

  const getGameTypes = () => {
    return [
      gameTypes.map(x =>
        <div className={`${styles.game}`}>
          {NullIf.isNotNullOrEmpty(x[1]) ?
            <Link to={r.get(locale, translations, x[1], '')}>
              {getItemIcons(casino, x)}
            </Link>
            : getItemIcons(casino, x)
          }
        </div>
      ),
      casino.extendedData &&
      extendedDataTypes.map(x =>
        <div className={`${styles.game}`}>
          {NullIf.isNotNullOrEmpty(x[1]) ?
            <Link to={r.get(locale, translations, x[1], '')}>
              {getItemIcons(casino.extendedData, x)}
            </Link>
            : getItemIcons(casino.extendedData, x)
          }
        </div>
      )
    ]
  }


  return (
    <section className={`${styles.gameTypes}`}>
      <div className={`${styles.casinoGameTypes}`}>
        <h2 id={`${addNamedClass(casino, 'review-rating-data-header-2')}`} className={`${styles.title}`}>{t.get(translations, "casino-review-game-types")}</h2>
        {getGameTypes()}
      </div>
    </section>
  )
}

const getGroups = (items, count, translations, locale, casino, routeName, rvPart) => {
  let filteredItems = items && items.filter(x => removeDisabledPaymentMethodFilter(x, locale)).filter(x => x.largeIcon && x.largeIcon.asset);
  return (
    <div className={`${styles.casinoLogoGroups}`} style={{ "--count": count + 1 }}>
      {items &&
        filteredItems.slice(0, count).map(x => {
        return (
          <span>
              <span
                id={`${addNamedClass(casino, 'review-grouped-data-link-for-' + x.displayName || x.name)}`}
                key={x.name}
              >
                {x.largeIcon && x.largeIcon.asset && (
                <img id={`${addNamedClass(casino, 'review-grouped-data-image-for-' + x.name)}`} src={x.largeIcon.asset.url} alt={x.displayName || x.name} title={`${x.name} ${t.get(translations, "casinos")}`}/>
                )}
              </span>
          </span>
        )
      })
      }

      {filteredItems.length > 9 ?
        <Button className={`${styles.logoMoreButton} ${styles.type1}`} color="primary">
          <a href={`#part-${rvPart}`}>
              +{filteredItems.length - 9} {t.get(translations, "casino-review-more")}
          </a>
        </Button> :
        ""
      }
      {filteredItems.length > 7 ?
        <Button className={`${styles.logoMoreButton} ${styles.type2}`} color="primary">
          <a href={`#part-${rvPart}`}>
              +{filteredItems.length - 7} {t.get(translations, "casino-review-more")}
          </a>
        </Button> :
        ""
      }
      {filteredItems.length > 6 ?
        <Button className={`${styles.logoMoreButton} ${styles.type3}`} color="primary">
          <a href={`#part-${rvPart}`}>
              +{filteredItems.length - 6} {t.get(translations, "casino-review-more")}
          </a>
        </Button> :
        ""
      }
      {filteredItems.length > 4 ?
        <Button className={`${styles.logoMoreButton} ${styles.type4}`} color="primary">
          <a href={`#part-${rvPart}`}>
              +{filteredItems.length - 4} {t.get(translations, "casino-review-more")}
          </a>
        </Button> :
        ""
      }
      {filteredItems.length > 3 ?
        <Button className={`${styles.logoMoreButton} ${styles.type5}`} color="primary">
          <a href={`#${rvPart}`}>
              +{filteredItems.length - 3} {t.get(translations, "casino-review-more")}
          </a>
        </Button> :
        ""
      }
    </div>
  )
}

const CasinoGameProviders = ({ casino, translations, locale }) => {
  return (
    <section className={`${styles.gameProviders}`}>
      <div className={`${styles.casinoGameProviders}`}>
        <h2 id={`${addNamedClass(casino, 'review-rating-data-header-2')}`} className={`${styles.title}`}>{t.get(translations, "casino-review-game-providers")}</h2>
        {casino.gameProviders && getGroups(casino.gameProviders, 9, translations, locale, casino, 'gameProvider', t.get(translations, 'review-sticky-header-games'))}
      </div>
    </section>
  )
}

const removeDisabledPaymentMethodFilter = (method, currentLocale) => {
  if (method.disabledLocales){
    if (method.disabledLocales.some(x => currentLocale === x)) {
      return false;
    }
  }
  return true;
}

const CasinoDepositMethodes = ({ casino, translations, locale }) => {
  return (
    <section className={`${styles.depositMethods}`}>
      {casino.depositMethods && getGroups(casino.depositMethods.filter(x => removeDisabledPaymentMethodFilter(x, locale)), 9, translations, locale, casino, 'depositMethods', t.get(translations, 'review-sticky-header-banking'))}
    </section>
  )
}

const CasinoWithdrawalMethodes = ({ casino, translations, locale }) => {
  return (
    <section className={`${styles.withdrawalMethodes}`}>
      {casino.withdrawalMethods && getGroups(casino.withdrawalMethods.filter(x => removeDisabledPaymentMethodFilter(x, locale)), 9, translations, locale, casino, 'withdrawalMethods', t.get(translations, 'review-sticky-header-banking'))}
    </section>
  )
}

const TickCrossArea = ({ title, isTick, url, casino, deactivated }) => {
  return (
    <div id={`${addNamedClass(casino,'approval') + title}`} className={`${styles.tickCross}`}>
      {isTick ? (
        <CheckIcon className={`${styles.checkIcon}`} />
      ) : (
        <ClearIcon className={`${styles.crossIcon}`} />
      )}
      {url === "#" ?
        deactivated ? <a>{title}</a> : <span>{title}</span>
        :
        <Link to={url}><span>{title}</span></Link>
      }
    </div>
  )
}

const TickCrossAreaGroup = ({ locale, translations, casino }) => {
  return (
    <div className={`${styles.tickCrossContainer}`}>
      <TickCrossArea
        isTick={casino.isMobileFriendly}
        title={t.get(translations, "Mobile Casino")}
        url={r.get(
          locale,
          translations,
          "mobileFriendly",
          t.getBool(translations, casino.isMobileFriendly)
        )}
        casino={casino}
      />
      <TickCrossArea
        isTick={casino.hasGamification}
        title={t.get(translations, "Has Gamification")}
        url={r.get(
          locale,
          translations,
          "gamification",
          t.getBool(translations, casino.hasGamification)
        )}
        casino={casino}
      />
      {locale === "fi" &&
      <TickCrossArea
        isTick={casino.isTaxExempt}
        title={t.get(translations, "Tax Exempt")}
        url={r.get(
          locale,
          translations,
          "taxExempt",
          t.getBool(translations, casino.isTaxExempt)
        )}
        casino={casino}
      />
      }
      <TickCrossArea
        isTick={casino.hasVipProgram}
        title={t.get(translations, "casino-hasVipProgram")}
        url={'#'}
        deactivated={true}
        casino={casino}
      />
       <TickCrossArea
        isTick={casino.hasNoAccount}
        title={t.get(translations, "casino-has-noaccount")}
        url={locale === 'fi'? 'fi/pay-n-play-kasinot/' : '#'}
        deactivated={true}
        casino={casino}
      />
      {casino.bonuses && (
        <TickCrossArea
          isTick={casino.hasLiveChat}
          title={t.get(translations, "casino-has-livechat")}
          url={'#'}
          deactivated={true}
          casino={casino}
        />
      )}
    </div>
  )
}

const CasinoReviewBody = ({ locale, translations, userRatings, casino, review, reviews, fetchReviews, setReviews, template }) => {

  const [menuOpen, setMenuOpen] = useState(false)

  let reviewText = review._rawReviewText

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const ReviewMenu = ({ translations }) => {
    return (
      <div className={`${styles.reviewMenuWrapper}`}>
        <span className={`${styles.iconWrapper} ${menuOpen ? "" : styles.reviewMenuClosed}`} onClick={toggleMenu}>
          {menuOpen ?
            <CloseIcon className={`${styles.menuIcon}`} /> :
            <MenuIcon className={`${styles.menuIcon}`} />
          }
          <span className={`${styles.menuTitle} ${menuOpen ? "" : styles.hideTitle}`}></span>
        </span>
        <div className={`${styles.reviewMenuContainer} ${menuOpen ? styles.menuVisible  : ""}`}>
          <Slider disableNav={true}>
            <nav className={`${styles.reviewMenu}`}>
              <a onClick={toggleMenu} href={`#${t.get(translations, 'review-sticky-header-review')}`}><LinkIcon />{t.get(translations, 'review-sticky-header-review')}</a>
              <a onClick={toggleMenu} href={`#${t.get(translations, 'review-sticky-header-bonus')}`}><LinkIcon />{t.get(translations, 'review-sticky-header-bonus')}</a>
              <a onClick={toggleMenu} href={reviewText ? `#${t.get(translations, 'review-sticky-header-freespins')}` : `#${t.get(translations, 'review-sticky-header-bonus')}`}><LinkIcon />{t.get(translations, 'review-sticky-header-freespins')}</a>
              <a onClick={toggleMenu} href={`#${t.get(translations, 'review-sticky-header-games')}`}><LinkIcon />{t.get(translations, 'review-sticky-header-games')}</a>
              <a onClick={toggleMenu} href={`#${t.get(translations, 'review-sticky-header-mobile')}`}><LinkIcon />{t.get(translations, 'review-sticky-header-mobile')}</a>
              <a onClick={toggleMenu} href={`#${t.get(translations, 'review-sticky-header-banking')}`}><LinkIcon />{t.get(translations, 'review-sticky-header-banking')}</a>
              <a onClick={toggleMenu} href={`#${t.get(translations, 'review-sticky-header-support')}`}><LinkIcon />{t.get(translations, 'review-sticky-header-support')}</a>
              <a onClick={toggleMenu} href={`#${t.get(translations, 'review-sticky-header-summary')}`}><LinkIcon />{t.get(translations, 'review-sticky-header-summary')}</a>
              <a onClick={toggleMenu} href={`#${t.get(translations, 'review-sticky-header-user-reviews').toLowerCase().replace(/\s/, "-")}`}><LinkIcon />{t.get(translations, 'review-sticky-header-user-reviews')}</a>
            </nav>
          </Slider>
        </div>
      </div>
    );
  }

  return reviewText ?
  [<div className={`${styles.reviewBotcontainer}`}>
    <ReviewMenu translations={translations} />
    <FloatingInfoCard userRatings={userRatings} translations={translations} casino={casino} locale={locale} reviews={reviews}/>
    <PortableTextToTabs blocks={reviewText} loggedInUser={true} fetchReviews={fetchReviews} setReviews={setReviews} casino={casino} reviews={reviews} translations={translations} locale={locale} />
  </div> ]
  :
  [<div className={`${styles.reviewBotcontainer}`}>
    <ReviewMenu translations={translations} />
    <FloatingInfoCard userRatings={userRatings} translations={translations} casino={casino} locale={locale} reviews={reviews} />
    <div className={`${styles.reviewBottom}`}>
      <div className={`${styles.content}`}>
         <ContentAi template={template} casino={casino} locale={locale} translations={translations} />
         <div className={`commentsWrapper ${styles.partsContainer}`} id={t.get(translations, 'review-sticky-header-user-reviews').toLowerCase().replace(/\s/, "-")}>
          <Comments reviews={reviews} setReviews={setReviews} fetchReviews={fetchReviews} loggedInUser={true} casino={casino} translations={translations} locale={locale} />
         </div>
      </div>
    </div>
  </div>]
}

{/* <p id={`${addNamedClass(casino, 'review-data-max-bet')}`} className={`${styles.maxBet}`}>{t.get(translations, 'max-bet')}: {casino.maxBet}{t.get(translations, 'currency')}</p>
  <p id={`${addNamedClass(casino, 'review-freespin-slots-information')}`} className={`${styles.freeSpinSlots}`}>{`${t.get(translations, 'freespin-slots')}: `}
    {
      getFreespins(casino, locale, translations)
    }
  </p> */}

const ACasino = props => {
  const { pageContext, location, data } = props
  let { locale, userReviews } = pageContext;
  let { alternateLocaleSlugs } = localizeItem(pageContext, [locale]);

  let { translations } = useContext(ThemeContext)
  let casino = localizeItem(data.sanityCasino, [locale]);
  let review = localizeItem(data.sanityCasinoReview, [locale]);
  let template = localizeItem(data.sanityContentAi, [locale]);

  const [hasErrors, setErrors] = useState(false)
  const [reviews, setReviews] = useState({})

  useEffect(() => {
    fetchUserReviews(locale, casino)
      .then(res => setReviews(res))
      .catch(err => setErrors(err));
  }, []);

  return (
    <Layout silo={localizeItem(data.sanitySilo, [locale])} pageContext={pageContext} location={location} bcDark>

      <SEO location={location} alternateSlug={alternateLocaleSlugs}
           casino={casino}
           useEnglishGlobal={review._rawReviewText ? false : true}
           casinoReview={review}
           userRatings={userReviews[0] || {}}
           title={
             (review.seoMetaTag)
               ? review.seoMetaTag
               : (casino.name + ' ' + t.get(translations, 'default-review-title'))
           }
           description={
             review.seoMetaDescription
           }
      />
      <div className={`${styles.reviewPageMainParent}`}>
        <div className={`${styles.reviewHead}`}>
          {/* <CasinoLicenseWarning translations={translations} locale={locale} casino={casino} /> */}
          <CasinoReviewTitle translations={translations} review={review} casino={casino} />
          <CasinoPubDates translations={translations} casino={casino} review={review} />
          <MainBanner translations={translations} casino={casino} locale={locale} reviews={reviews}/>
          <CasinoSideBar translations={translations} casino={casino} locale={locale} />
          <CasinoGameTypes translations={translations} casino={casino} locale={locale} />
          <CasinoGameProviders translations={translations} casino={casino} locale={locale} />
          <CasinoDepAndWith translations={translations} casino={casino} locale={locale} />
        </div>
        <div className={`${styles.reviewBody}`}>
          <CasinoReviewBody userRatings={userReviews[0]} translations={translations} template={template.content} locale={locale} casino={casino} review={review} reviews={reviews} fetchReviews={fetchUserReviews} setReviews={setReviews}/>
        </div>
        <div className={`gamble-hidden-lg-up ${styles.reviewPageFloatingPlayButton}`}>
          {casino.logo && casino.logo.asset && (
            <a
              id={`${addNamedClass(casino, 'bonus-card-play-cta')}`}
              target={`_blank`} rel={`noopener noreferrer nofollow`}
              href={casino.extendedData && casino.extendedData.closed === true ? "#" : r.get(locale, translations, "redirectPage", casino.affiliateMask)}>
              <Img
                id={`${addNamedClass(casino, 'logo')}`}
                fluid={casino.logo.asset.fluid}
                alt={`${casino.name}-logo`}
              />
            </a>
          )}
          {casino.extendedData && casino.extendedData.closed === true ?
            <Button className={`${styles.floatingCtaCasinoClosed}`} disabled={true} >
              <a
                id={`${addNamedClass(casino, 'bonus-card-play-cta')}`}
                target={`_blank`} rel={`noopener noreferrer nofollow`}
                href={"#"}
              >
                <span className={`${styles.ctaCasinoClosed}`}>
                  <BlockIcon />
                  {t.get(translations, 'casino-closed')}!
                </span>
                {/* <span>{t.get(translations,  "terms-and-conditions")}</span> */}
              </a>
            </Button>
            :
            <Button>
              <a
                id={`${addNamedClass(casino, 'bonus-card-play-cta')}`}
                target={`_blank`} rel={`noopener noreferrer nofollow`}
                href={r.get(locale, translations, "redirectPage", casino.affiliateMask)}
              >
                  {t.get(translations, "play-now")}
                  {/* <span>{t.get(translations,  "terms-and-conditions")}</span> */}
              </a>
            </Button>
          }

        </div>
      </div>
    </Layout>
  )
}
export default localize(ACasino)
export const query = graphql`
  query Review($casinoName: String!) {
      sanitySilo (_id: {eq: "bc718067-0d15-409b-88e8-5f931a664b61"}){
          _id
          breadcrumbTrail {
              ...LocaleSiloTrails
          }
      }
      sanityCasinoReview(casino: {name: {eq: $casinoName}}) {
          ...CasinoReviewFull
      }
      sanityCasino(name: {eq: $casinoName}) {
        ...CasinoFull
      }
      sanityContentAi(_id: {eq: "ae6fe1cf-8a63-4136-8e27-74412d4d96db"}){
          content {
              ...LocaleText
          }
      }

}`