import React, { useContext, useState } from "react"
import r from "../../services/Router"
import BonusHelper from '../../services/casino-bonus-helpers'
import t from "../../services/GetTranslationValue"
import MailIcon from "@material-ui/icons/Mail"
import CallIcon from "@material-ui/icons/Call"
import ForumIcon from "@material-ui/icons/Forum"
import styles from "../../templates/casino-review/item.module.scss"
import Comments from "../comments/comments"
import BaseBlockContent from '@sanity/block-content-to-react'
import { getLocaleAlias } from "../../graphql-assistant/createLocaleTextGetter"


const PortableTextToTabs = ({ blocks, casino, loggedInUser, reviews, setReviews, fetchReviews, translations, locale }) => {

  const convertLinkToCurrentLocale = (link, locale) => {
    if (link.endsWith('/ilman-rekisteroitymista/')){
      link = link.replace('ilman-rekisteroitymista', 'nettikasinot-ilman-rekisteroitymista')
    }

    if (locale !== 'fi') {
      if (link.startsWith('https://mr-gamble.com/')){
        return link.replace("/en/", `/${getLocaleAlias(locale)}/`);
      }


    }

    return link;
  }
  const serializers = {
    marks: {
      emphasis(props) {
        return <strong>{props.children}</strong>
      },
      strong(props) {
        if (locale === 'fi'){
          return <strong>{props.children}</strong>
        }
        return <span className={'highlight'}>{props.children}</span>
      },
      renderstrong(props) {
        return <strong>{props.children}</strong>
      },
      hyperlink(props) {
        if (props.mark && props.mark.href){
          if (props.mark.href.includes('!!nofollow!!'))
            return <a href={`${props.mark.href.replace('!!nofollow!!', '')}`} target={`_blank`} rel={`nofollow`}>{props.children[0]}</a>

          return (
            <a href={convertLinkToCurrentLocale(props.mark.href, locale)}> {props.children[0]} </a>
          )
        }

        return props.children;
      },
      link(props) {
        if (props.mark && props.mark.href){
          if (props.mark.href.includes('!!nofollow!!'))
            return <a href={`${props.mark.href.replace('!!nofollow!!', '')}`} target={`_blank`} rel={`nofollow`}>{props.children[0]}</a>

          return (
            <a href={convertLinkToCurrentLocale(props.mark.href, locale)}> {props.children[0]} </a>
          )
        }

        return props.children;
      }
    }
  }
  const addNamedClass = (casino, suffix) => {
    if (casino.name === 'Rizk')
      return casino.name + '-' + suffix;

    return '';
  }

  const getWageringText = (casino, translations) => {
    let bonus = BonusHelper.getBonusToUse(casino, false)
    if (bonus && bonus.wageringRequirement && bonus.wageringRequirementType)
      return `${bonus.wageringRequirement}${t.get(translations, bonus.wageringRequirementType)}`

    if (!bonus)
      return '-'

    return "0x"
  }

  const makeFreespinCards = (casino) => {

    let fsND = BonusHelper.GetFreespinNoDepositBonus(casino);
    let fs = BonusHelper.GetFirstDepositBonus(casino);


    return [
      makeFreespinCard(fs, true),
      makeFreespinCard(fsND, false),
    ];
  }

  const makeFreespinCard = (bonus, requiresDeposit) => {
    return bonus && bonus.freespinsAmount > 0 &&
      <div className={`free-spin ${styles.freeSpin}`}>
        <p className={`free-spin-text ${styles.freeSpinText}`}>{t.get(translations, 'freespin')}</p>
        <p className={`free-spin-amount ${styles.freeSpinAmount}`}>{bonus.freespinsAmount}</p>
        <p className={`free-spin-deposit ${styles.freeSpinDeposit}`}>{requiresDeposit ? t.get(translations, 'with') : t.get(translations, 'without')} {t.get(translations, 'deposit')}</p>
        <p className={`${styles.bonusCardsWagering}`}>
          <label>{t.get(translations, "Wagering Requirement")}:</label>
          <span>{getWageringText(casino, translations)}</span>
        </p>
        <a
          target={`_blank`} rel={`noopener noreferrer nofollow`}
          href={r.get(locale, translations, "redirectPage", casino.affiliateMask)} >
          <p className={`play-button ${styles.playButton}`}>{t.get(translations, 'bonus-text')}</p>
        </a>
      </div>
  }

  /* TODO: move this crap to a helper so that there is no duplicate code (all makeXXXCard functions), content-ai.js has the same code! :( */
  const makeCashbackCard = (casino, bonus) => {
    if (bonus) {
      return <div id={`${addNamedClass(casino, 'tab-bonus-card-deposit')}`} className={`${styles.bonusCard}`}>
        <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-text-deposit')}`} className={`deposit-bonus-text ${styles.depositBonusText}`}>{t.get(translations, 'bonus-text-cashback')}</p>
        {bonus.bonusValue && <p className={`deposit-bonus-amount ${styles.depositBonusAmount}`}>{bonus.bonusValue}%</p>}
        {bonus.type && <p className={`welcome-bonus-text ${styles.welcomeBonusText}`}>{bonus.type.name}</p>}
        <p id={`${addNamedClass(casino, 'tab-bonus-card-cashback-maximum-amount')}`} className={`cashback-bonus-amount ${styles.cashbackBonusAmount}`}>
        {bonus.cashbackIsPerSpin ? <span>per<br/>{t.get(translations, 'per-spin')}</span>  : bonus.maximumBonusAmount ? <span>max<br/>{ bonus.maximumBonusAmount + t.get(translations, 'currency')}</span> : t.get(translations, "bonus-no-max-amount")}</p>
        <p className={`${styles.bonusCardsWagering}`}>
          <label>{t.get(translations, "Wagering Requirement")}:</label>
          <span>{getWageringText(casino, translations)}</span>
        </p>
        <a
          id={`${addNamedClass(casino, 'tab-bonus-card-bonus-cta')}`}
          target={`_blank`} rel={`noopener noreferrer nofollow`}
          href={r.get(locale, translations, "redirectPage", casino.affiliateMask)} >
          <p className={`play-button ${styles.playbutton}`}>{t.get(translations, 'bonus-text')}</p>
        </a>
        {bonus.freespinsAmount && <p className={`free-spins-amount ${styles.freeSpinsAmount}`}>+ {bonus.freespinsAmount} {t.get(translations, 'freespins-tab-card')}</p>}
      </div>
    }
  }

  const makeBonusCard = (casino, bonus, index) => {
    if (bonus) {
      if (bonus.bonusValue) {
        return <div id={`${addNamedClass(casino, 'tab-bonus-card-' + index)}`} className={`${styles.bonusCard}`}>
          <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-text-' + index)}`} className={`deposit-bonus-text ${styles.depositBonusText}`}>{index}. {t.get(translations, 'bonus-text-deposit')}</p>
          {bonus.bonusValue && <p className={`deposit-bonus-amount ${styles.depositBonusAmount}`}>{bonus.bonusValue}%</p>}
          {bonus.type && <p className={`welcome-bonus-text ${styles.welcomeBonusText}`}>{bonus.type.name}</p>}
          <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-welcome-text-' + index)}`} className={`welcome-bonus-amount ${styles.welcomeBonusAmount}`}>{bonus.maximumBonusAmount}</p>
          <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-currency-' + index)}`} className={`currency ${styles.currency}`}>{t.get(translations, 'currency')}</p>
          <p className={`${styles.bonusCardsWagering}`}>
            <label>{t.get(translations, "Wagering Requirement")}:</label>
            <span>{getWageringText(casino, translations)}</span>
          </p>
          <a
            id={`${addNamedClass(casino, 'tab-bonus-card-bonus-cta-' + index)}`}
            target={`_blank`} rel={`noopener noreferrer nofollow`}
            href={r.get(locale, translations, "redirectPage", casino.affiliateMask)} >
            <p className={`play-button ${styles.playbutton}`}>{t.get(translations, 'bonus-text')}</p>
          </a>
          {bonus.freespinsAmount && <p className={`free-spins-amount ${styles.freeSpinsAmount}`}>+ {bonus.freespinsAmount} {t.get(translations, 'freespins-tab-card')}</p>}
        </div>
      }
      // must be a freespins bonus
      else {
        return <div id={`${addNamedClass(casino, 'tab-bonus-card-bonus-freespin-' + index)}`} className={`${styles.bonusCard}`}>
          <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-text-freespin-' + index)}`} className={`deposit-bonus-text ${styles.depositBonusText}`}>{index}. {t.get(translations, 'bonus-text-deposit')}</p>
          <p className={`deposit-bonus-amount ${styles.depositBonusAmount}`}></p>
          {bonus.type && <p className={`welcome-bonus-text ${styles.welcomeBonusText}`}>{bonus.type.name}</p>}
          {<p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-freespin-welcome-text-' + index)}`} className={`welcome-bonus-amount ${styles.welcomeBonusAmount}`}>{bonus.freespinsAmount}</p>}
          <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-currency-freespin-text-' + index)}`} className={`currency ${styles.currency}`}>{t.get(translations, 'freespins')}</p>
          <p id={`${addNamedClass(casino, 'tab-bonus-card-bonus-currenct-freespin-info-text-' + index)}`} className={`free-spins-slot-machines ${styles.freeSpinsSlotMachines}`}>{bonus.relatedGames.slice(0, 3).map((x, index) => x.name + (index < 2 ? ', ' : '...'))}</p>
          <p id={`${addNamedClass(casino, 'tab-bonus-card-cashback-maximum-amount')}`} className={`cashback-bonus-amount ${styles.cashbackBonusAmount}`}>{bonus.bonusValue}% / {bonus.cashbackIsPerSpin ? t.get(translations, 'per-spin')  : bonus.maximumBonusAmount ? bonus.maximumBonusAmount + t.get(translations, 'currency') :  t.get(translations, "bonus-no-max-amount")}</p>
          <p className={`${styles.bonusCardsWagering}`}>
            <label>{t.get(translations, "Wagering Requirement")}:</label>
            <span>{getWageringText(casino, translations)}</span>
          </p>
          <a
            id={`${addNamedClass(casino, 'tab-bonus-card-bonus-freespin-text-cta-' + index)}`}
            target={`_blank`} rel={`noopener noreferrer nofollow`}
            href={r.get(locale, translations, "redirectPage", casino.affiliateMask)} >
            <p className={`play-button ${styles.playbutton}`}>{t.get(translations, 'bonus-text')}</p>
          </a>
        </div>
      }
    }
    return ''
  }

  const makeBonusCards = (casino) => {
    return [ makeBonusCard(casino, BonusHelper.GetFirstDepositBonus(casino), 1),
            makeBonusCard(casino, BonusHelper.GetSecondDepositBonus(casino), 2),
            makeBonusCard(casino, BonusHelper.GetThirdDepositBonus(casino), 3),
            makeBonusCard(casino, BonusHelper.GetFourthDepositBonus(casino), 4),
            makeBonusCard(casino, BonusHelper.GetFifthDepositBonus(casino), 5),
            makeBonusCard(casino, BonusHelper.GetSixthDepositBonus(casino), 6),
            makeCashbackCard(casino, BonusHelper.GetCashbackBonus(casino))
    ];
  }

  const getGroups = (items, count, translations, locale, casino, routeName) => {

    return (
      <div className={`${styles.casinoLogoGroups}`}>
        {items &&
          items.map(x => {
            return (
              <span>
                <span
                  id={`${addNamedClass(casino, 'review-grouped-data-link-for-' + x.displayName || x.name)}`}
                  key={x.name}
                >
                  {x.largeIcon && x.largeIcon.asset && (
                    <img id={`${addNamedClass(casino, 'review-grouped-data-image-for-' + x.name)}`} src={x.largeIcon.asset.url} alt={x.displayName || x.name} title={`${x.name} ${t.get(translations, "casinos")}`}/>
                  )}
                </span>
              </span>
            )
          })
        }
      </div>
    )
  }

  const makeGameProviders = (casino) => {
    return (
      <div className={`${styles.logoContainer}`}>
        {casino.gameProviders &&
          getGroups(casino.gameProviders, 9, translations, locale, casino, 'gameProvider')}
      </div>
    )
  }

  const removeDisabledPaymentMethodFilter = (method, currentLocale) => {
    if (method.disabledLocales){
      if (method.disabledLocales.some(x => currentLocale === x)) {
        return false;
      }
    }
    return true;
  }
  const makeWithdrawalMethods = (casino) => {
    return (
      <div className={`${styles.depositWithdrawalContainer}`}>
        <p>{t.get(translations, 'Deposit Methods')}</p>
        <div className={`${styles.logoContainer}`}>
          {casino.depositMethods &&
            getGroups(casino.depositMethods.filter(x => removeDisabledPaymentMethodFilter(x, locale)), 4, translations, locale, casino, 'depositMethods')}
        </div>
        <p>{t.get(translations, 'Withdrawal Methods')}</p>
        <div className={`${styles.logoContainer}`}>
          {casino.withdrawalMethods &&
            getGroups(casino.withdrawalMethods.filter(x => removeDisabledPaymentMethodFilter(x, locale)), 4, translations, locale, casino, 'withdrawalMethods')}
        </div>
      </div>
    )
  }

  const makeCustomerSupport = (casino) => {
    const tel = casino.telephoneNumber;
    const email = casino.supportEmail;
    const liveChat = casino.hasLiveChat;

    return (
      <div className={`${styles.supportContainer}`}>
        <h3>{t.get(translations, 'customer-support-options')}</h3>
        {tel &&
          <div className={`${styles.phone}`}>
            <p><CallIcon /></p>
            <span>{t.get(translations, 'phone-support')}</span>
          </div>
        }
        {email &&
          <div className={`${styles.email}`}>
            <p><MailIcon /></p>
            <span>{t.get(translations, 'email-support')}</span>
          </div>
        }
        {liveChat &&
          <div className={`${styles.chat}`}>
            <p><ForumIcon /></p>
            <span>{t.get(translations, 'chat-support')}</span>
          </div>
        }
      </div>
    )
  }

/*   const reviewsOld = [
    {
      name: "Alexander",
      title: "Amazing bonuses!",
      pic: "/icons/casiplay.jpg",
      date: "8/4/2020",
      message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      rate: 4.5,
      likes: 20
    },
    {
      name: "William",
      title: "Very good game variety. I definitely recommend this casino.",
      pic: "/icons/casiplay.jpg",
      date: "6/4/2020",
      message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      rate: 3.74,
      likes: 5
    },
    {
      name: "Chris",
      title: "Poor banking options",
      pic: "/icons/casiplay.jpg",
      date: "2/4/2020",
      message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      rate: 5,
      likes: 0
    },
  ] */

  return (
    <div className={`${styles.reviewBottom}`}>
      <div className={`${styles.content}`}>
        <div className={`${styles.partsContainer}`}>
          { blocks.map((x, index) => (
            <div className={`${styles.part}`}>
              {/* <p id={`part-${index + 1}`} className={`${styles.partAnchor}`}></p> */}
              {index === 0 && <p id={t.get(translations, 'review-sticky-header-review')} className={`${styles.partAnchor}`}></p>}
              {index === 1 && <p id={t.get(translations, 'review-sticky-header-bonus')} className={`${styles.partAnchor}`}></p>}
              {index === 2 && <p id={t.get(translations, 'review-sticky-header-freespins')} className={`${styles.partAnchor}`}></p>}
              {index === 4 && <p id={t.get(translations, 'review-sticky-header-games')} className={`${styles.partAnchor}`}></p>}
              {index === 5 && <p id={t.get(translations, 'review-sticky-header-mobile')} className={`${styles.partAnchor}`}></p>}
              {index === 6 && <p id={t.get(translations, 'review-sticky-header-banking')} className={`${styles.partAnchor}`}></p>}
              {index === 7 && <p id={t.get(translations, 'review-sticky-header-support')} className={`${styles.partAnchor}`}></p>}
              {index === 8 && <p id={t.get(translations, 'review-sticky-header-summary')} className={`${styles.partAnchor}`}></p>}
              <h2>{x.title}</h2>
              {index === 1 ?
                <div className={`${styles.bonusCardsContainer}`}>
                  {makeBonusCards(casino)}
                </div>
                : ""}
              {index === 2 ?
                <div className={`${styles.bonusCardsContainer}`}>
                  {makeFreespinCards(casino)}
                </div>
                : ""}
              {index === 4 ?
                <div>
                  {makeGameProviders(casino)}
                </div>
                : ""}
              {index === 6 ?
                <div>
                  {makeWithdrawalMethods(casino)}
                </div>
                : ""}
              {index === 7 ?
                <div>
                  {makeCustomerSupport(casino)}
                </div>
                : ""}
              <BaseBlockContent serializers={serializers} blocks={x.contentItem} />
              <BaseBlockContent serializers={serializers} blocks={x.footerContentItem}/>

            </div>
            )
          )}
          <div className={`commentsWrapper`} id={t.get(translations, 'review-sticky-header-user-reviews').toLowerCase().replace(/\s/, "-")}>
            <Comments reviews={reviews} setReviews={setReviews} fetchReviews={fetchReviews} loggedInUser={true} casino={casino} translations={translations} locale={locale} />
          </div>
        </div>
      </div>
      {/* <div>{JSON.stringify(blocks)}</div>  */}
    </div>
  )
}
export default PortableTextToTabs;