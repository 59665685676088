import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useContext, useState } from "react"
import ThemeContext from "../../context/ThemeContext"
import styles from "./comments.module.scss"
import r from "../../services/Router"
import t from "../../services/GetTranslationValue"
import ReplyIcon from '@material-ui/icons/Reply';
import ShareIcon from '@material-ui/icons/Share';
import { getLocaleAlias, localizeItem } from "../../graphql-assistant/createLocaleTextGetter"
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import IconButton from '@material-ui/core/IconButton';
import Rating from "@material-ui/lab/Rating"
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import InfoIcon from '@material-ui/icons/Info';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button"
import Snackbar from '@material-ui/core/Snackbar';
import Login from "../login/login"
import Alert from '@material-ui/lab/Alert';
import { isLoggedIn, getUser } from "../../services/auth";
import CKEditor from 'ckeditor4-react';
import { canUseDOM } from '../../services/dom-helpers'
import { Input } from "@material-ui/core"
import { API_ROOT } from "gatsby-env-variables"
import { likeUserReview, createUserReview } from "../../api/client/userReviews"

const Comments = ({reviews, setReviews, fetchReviews, loggedInUser, casino, translations, locale}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  let [loginOpen, setLoginOpen] = useState(false)
  let [loginIndex, setLoginIndex] = useState(0)

  const user = canUseDOM ? localStorage.getItem('oauth') || null : null

  let site = useContext(ThemeContext);

  const [rating, setRating] = useState("")
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [errors, setErrors] = useState('')
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [showRequirements, setShowRequirements] = useState(false)

  let commentReqTester = rating === "" || rating === null || title === "" || body === "";
  const defaultPic = casino.logo.asset.fluid.src;

  const emptyUserReviewForm = () => {
    setRating(0)

    setTitle('')

    setBody('')
  }

  const handleUpDownVote = (id, vote, oldVote) => async event => {
    event.preventDefault()

    const res = await likeUserReview(id, vote == oldVote ? 0 : vote)

    fetchReviews(locale, casino)
      .then(res => setReviews(res))
      .catch(err => setErrors(err))
  }

  const handleSubmitCommit = async event => {
    if (commentReqTester) {
      setShowRequirements(true);
    } else {
      setShowRequirements(false);
      event.preventDefault()

      setHasSubmitted(true);

      const comment = {
        commentTitle: title,
        commentBody: body,
        rating: rating,
        autoAccept: true
      }

      const res = await createUserReview(comment, casino, locale)

      emptyUserReviewForm()

      fetchReviews(locale, casino)
        .then(res => setReviews(res))
        .catch(err => setErrors(err))

      setHasSubmitted(false);
      toggleConfirmation();

    }
  }

  const toggleConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  }

  const lpClose = () => {
    setLoginOpen(false);
  }

  const toggleLoginPopup = (index) => {
    setLoginOpen(true);
    setLoginIndex(index);
  }

  const reviewRequirements = () => {
    return (
      <div className={`${styles.commentRequirements}`}>
        <p id="review-requirements" className={`${styles.anchorHelper}`}></p>
        <ul>
          {(rating === "" || rating === null) && <li><span>you must rate the casino</span></li>}
          {title === "" && <li><span>reviews must have a title</span></li>}
          {body === "" && <li><span>reviews content must not be empty</span></li>}
        </ul>
      </div>
    )
  }

  const NotLoggedIn = ({ translations, locale }) => {
    return (
      <div className={`${styles.loginWrapper}`}>
        <div className={`${styles.loginWarning}`}>
          <span><InfoIcon />{t.get(translations, 'comments-need-to-login')}</span>
          <Button
            variant="contained"
            color="primary"
            className={`${styles.loginButton}`}
            onClick={() => toggleLoginPopup(0)}
          >
            {t.get(translations, 'login-sign-in')}
          </Button>
        </div>
        <div className={`${styles.benefits}`}>
          <p>{t.get(translations, 'comments-login-benefit-title')}</p>
          <ul>
            <li>{t.get(translations, 'comments-login-benefit-1')}</li>
            <li>{t.get(translations, 'comments-login-benefit-2')}</li>
            <li>{t.get(translations, 'comments-login-benefit-3')}</li>
            <li>{t.get(translations, 'comments-login-benefit-4')}</li>
          </ul>
        </div>
      </div>
    )
  }

  return (
    <section className={`${styles.commentsWrapper}`}>
      <Login isOpen={loginOpen} tabIndex={loginIndex} lpClose={lpClose} />
      <h2 className={`${styles.reviewsTitle}`}>{t.get(translations, 'casino-review-user-reviews-title')}</h2>
      <p className={`${styles.reviewsGuide}`}>{t.get(translations, 'casino-review-user-reviews-guide')}</p>
      {isLoggedIn() ?
        <form className={`${styles.reviewForm}`}>
          <div className={`${styles.ratingForm}`}>
            <span className={`${styles.ratingText}`}>{t.get(translations, 'casino-review-user-reviews-rating-text')} <span>{casino.name}</span> :</span>
            <p className={`commentsRating`}>
              <Rating
                value={rating}
                readOnly={false}
                onChange={(_, val) => setRating(val)}
                name="half-rating"
                emptyIcon={<span className={`${styles.emptyChip}`} />}
                icon={<span className={`${styles.fullChip}`} />}
                size="small"
                precision={0.5}
              />
            </p>
          </div>
          <div className={`${styles.fieldsWrapper}`}>
            <TextField
                value={title}
                onChange={e => setTitle(e.target.value)}
                className={`${styles.title}`} label={t.get(translations, 'review-form-title')} variant="outlined" size="small" />
            <div className={`${styles.reviewContent}`}>
              <CKEditor
                data={body}
                onChange={e => setBody(e.editor.getData())}
                config={{
                  toolbar: [
                    { name: 'basicstyles', items: ['Bold', 'Italic'] },
                    { name: 'tools', items: ['Maximize'] },
                  ]
                }}
              />
            </div>
            {commentReqTester ?
              <a href="#review-requirements" >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmitCommit}
                  className={`${styles.reviewSubmit}`}
                >
                  {hasSubmitted ?
                    t.get(translations, 'casino-review-user-reviews-submitting-review')
                    :
                    t.get(translations, 'casino-review-user-reviews-submit-button')
                  }
                </Button>
              </a>
              :
              <Button
                disabled={hasSubmitted === true}
                variant="contained"
                color="secondary"
                onClick={handleSubmitCommit}
                className={`${styles.reviewSubmit}`}
              >
                {hasSubmitted ?
                  t.get(translations, 'casino-review-user-reviews-submitting-review')
                  :
                  t.get(translations, 'casino-review-user-reviews-submit-button')
                }
              </Button>
            }
          </div>
          <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={showConfirmation} autoHideDuration={70000} onClose={toggleConfirmation}>
            <Alert onClose={toggleConfirmation} severity="success">
              {t.get(translations, 'casino-review-user-reviews-submition-text')}
            </Alert>
          </Snackbar>
        </form>
        :
        <NotLoggedIn translations={translations} locale={locale} />
      }
      {reviews && reviews.comments && reviews.comments.length > 0 ? <hr/> : <br/>}
      {reviews && reviews.comments && reviews.comments.map(x => {
        return (
          <div className={`${styles.comment}`}>
            <header className={`${styles.header}`}>
              <div className={`${styles.userDetails}`}>
                <span className={`${styles.username}`}>{x.User.username}</span>
              </div>
              <p
                className={`${styles.ratingWrapper}`}
              >
                <Rating
                  readOnly={true}
                  name="half-rating"
                  emptyIcon={<span className={`${styles.emptyChip}`} />}
                  icon={<span className={`${styles.fullChip}`} />}
                  size="small"
                  value={parseFloat(x.rating)}
                  precision={0.5}
                />
              </p>
            </header>
            <div className={`${styles.body}`}>
              <p className={`${styles.title}`}><FormatQuoteIcon /><span>{x.commentTitle}</span><FormatQuoteIcon /></p>
              <div className={`${styles.text}`} dangerouslySetInnerHTML={{__html: x.commentBody}}>
              </div>
            </div>
            <footer className={`${styles.footer}`}>
              <span className={`${styles.date}`}>{x.createdAt.slice(0, 10)}</span>
              <div className={`${styles.likesCount}`}>
                <IconButton disabled={!isLoggedIn()} onClick={handleUpDownVote(x.id,-1,x.userVote)} aria-label="dislike" className={`${styles.dislike}`} size="small">
                  <ThumbDownIcon className={`${x.userVote < 0 ? styles.userVoted : ""}`}/>
                </IconButton>
                  <span>{x.likes}</span>
                <IconButton disabled={!isLoggedIn()} onClick={handleUpDownVote(x.id,1,x.userVote)} aria-label="like" className={`${styles.like}`} size="small">
                  <ThumbUpIcon className={`${x.userVote > 0 ? styles.userVoted : ""}`}/>
                </IconButton>
              </div>
            </footer>
          </div>
        )
      })

      }
    </section>
  )
}
export default Comments
