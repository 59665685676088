import { API_ROOT } from "gatsby-env-variables"
import { getUser } from "../../services/auth";
import { getAuthHeader } from "./headers";

export async function fetchUserReviewsByUser() {
  const user = localStorage ? (JSON.parse(localStorage.getItem('oauth')) || null) : null
  let headers = {}
  if (user && user.token) {
    headers = { 'Authorization': `Token ${user.token}`}
  }
  const res = await fetch(`${API_ROOT}/userComments`, {
    headers: headers,
    mode: 'cors'
  });
  return res
    .json();
}

export async function fetchUserReviews(locale, casino) {
  const user = localStorage ? (JSON.parse(localStorage.getItem('oauth')) || null) : null
  let headers = {}
  if (user && user.token) {
    headers = { 'Authorization': `Token ${user.token}`}
  }
  const res = await fetch(`${API_ROOT}/comment/${casino._id}/${locale}`, {
    headers: headers,
    mode: 'cors'
  });
  return res
    .json();
}

export async function createUserReview(comment, casino, locale) {
  const headers = getAuthHeader()

  const res = await fetch(`${API_ROOT}/comment/${casino._id}/${locale}`, {
    method: 'post',
    headers: new Headers(headers),
    body: JSON.stringify(comment),
  });
  return res
    .json();
}

export async function likeUserReview(id, vote) {
  const headers = getAuthHeader()
  const res = await fetch(`${API_ROOT}/likeComment`, {
    method: 'post',
    headers: new Headers(headers),
    body: JSON.stringify({
      id, vote
    })
  })
  return res
    .json();
}
